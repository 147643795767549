#id_container_page_1 {
  background: rgb(255, 255, 255, 0.2) url('../../assets/qcg.jpeg');
  background-size: 100%;
  height: 100vh;
}
#id_container_page_1 {
  color: blue;
}
#id_title_1 {
  color: grey;
  font-size: 50px;
  padding-top: 2%;
  padding-bottom: 2%;
}
#id_button_1 {
  padding-top: 2%;
}
#id_lock_1 {
  padding-top: 10px;
}

.divCenter_1 {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
#id_brasao_l {
  padding-bottom: 3%;
}
#id_container_page_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#id_container_form_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 7px;
  min-width: 10%;
  max-width: 300px;
  padding: 50px 20px 50px 20px;
}
.containerField10{
  display:flex;
  justify-content:center;
  flex-direction:column;
}

.g-recaptcha{
  width: 100%;
  margin: 7px;
  transform: scale(0.77); 
  -webkit-transform: scale(0.77); 
  transform-origin: 0 0; 
  -webkit-transform-origin: 0 0;
  justify-content: center;
  display: flex;
}