.Component-Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 90px;
  background-color: #a9a9b4;
  border: #fff 1px solid;
  border-radius: 10px;
  padding: 0 25px 50px;
}
.Box-Body {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  gap: 35px;
}
.Button {
  height: 50px;
  padding: 25px 32px;
  text-align: center;
  cursor: pointer;
}

.selectAutoComplite {
  width: 100%;
  height: 70px;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

@media (max-width: 600px) {
  .Component-Box {
    padding: 0 25px;
  }
  .Box-Body {
    width: 100%;
  }
  .Button {
    width: 100%;
  }
  .Button {
    height: 50px;
    padding: 25px 32px;
    text-align: center;
    cursor: pointer;
  }
}
