* {
  margin: 0;
  padding: 0;
}

.conteiner {
  display: flex;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: auto;
  background-color: #03063f;
  padding: 0 45px;
}

.img-logo img {
  max-width: 200px;
  left: 20px;
}

.img-avatar h4 {
  color: #fff;
}

.img-avatar {
  display: flex;
  gap: 25px;
}

.img-avatar-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.img-avatar-content h4 {
  margin: auto 10px;
}

@media (max-width: 768px) {
  .conteiner {
    height: 100px;
    justify-content: center;
  }

  .img-logo img {
    max-width: 150px;
  }

  .UserName {
    display: none;
  }
}
