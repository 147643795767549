.box {
  display: flex;
  background-color: #a9a9b4;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.box-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 30px;
  gap: 20px;
}

.box-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 30px;
  gap: 15px;
}

.box-img {
  display: flex;
  width: 80%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  gap: 10px;
}