.box-menu {
    display: flex;
    background-color: #a9a9b4;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: auto;
    margin-top: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }


@media screen and (max-width: 768px) {
    .box-menu {
        display: flex;
        background-color: #a9a9b4;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: auto;
     }
     
     .box-img {
        width: 100%;
        height: 100%;
        display: flex;
    }
    
}