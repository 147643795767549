* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: 0;
}

#containerPageAll1 {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
}

#containerFramePageAll1 {
  width: 100%;
}
