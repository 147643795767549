.Box-Menu {
  display: flex;
  flex-direction: column;
}

.button-voltar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
}

.ContainerMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ContainerIconMenu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  max-width: 200px;
  height: 100px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  cursor: pointer;
}

.idContainerNameIconMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  width: 50%;
  text-align: center;
}
@media (max-width: 800px) {
  .ContainerMenu {
    width: 100%;
  }
  .ContainerIconMenu {
    width: 98%;
    max-width: 200px;
  }
}
@media (max-width: 600px) {
  .ContainerMenu {
    width: 100%;
  }
  .ContainerIconMenu {
    width: 98%;
    max-width: 100%;
  }
}
