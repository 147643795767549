.idContainerIconMenu10 {
  display: flex;
  margin: 30px 10px;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  margin-bottom: 10px;
  border-radius: 20px;
  cursor: pointer;
}

.idContainerMenu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.idContainerNameIconMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
  height: 100%;
}

.idContainerMenu svg {
  font-size: 100px;
}

.idContainerMenu svg:hover {
  color: #134cb6;
}

.Component-Box-Buttons-Volta {
  display: flex;
  width: 100%;
  margin: 10px 0px 0px 0px;
}

@media (max-width: 1120px) {
  .idContainerMenu {
    display: flex;
    flex-wrap: wrap;
  }

  .idContainerIconMenu10 {
    display: flex;
    margin: 10px 10px;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100px;
    cursor: pointer;
  }
  .idContainerNameIconMenu {
    height: 70%;
  }
  .idContainerMenu svg {
    font-size: 50px;
  }

  .idContainerMenu svg:hover {
    color: #134cb6;
  }
}

@media (max-width: 800px) {
  .idContainerMenu {
    display: flex;
  }

  .idContainerIconMenu10 {
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100px;
    cursor: pointer;
  }
  .idContainerNameIconMenu {
    height: 20%;
  }
  .idContainerMenu svg {
    font-size: 50px;
  }

  .idContainerMenu svg:hover {
    color: #134cb6;
  }
}

@media (max-width: 600px) {
  .idContainerMenu {
    display: flex;
    flex-wrap: wrap;
  }

  .idContainerIconMenu10 {
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    cursor: pointer;
  }

  .idContainerMenu svg {
    font-size: 50px;
  }

  .idContainerMenu svg:hover {
    color: #134cb6;
  }
}
