.Box-Body {
  display: flex;
  width: 80%;
}

.box-permissao {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  padding: 10px;
}

.permissao-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
