.Box-Documento {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
}

.Box-collum-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
  gap: 15px;
}

.box-body {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

// .modal_code {
//   display: flex;
//   background-color: #f5f5f5;
//   align-items: center;
//   top: 70%;
//   left: 50%;
//   width: 25%;
//   height: 10%;
//   transform: translate(-50%, -50%);
//   position: absolute;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//   z-index: 1000;
//   gap: 15px;
//   justify-content: center;
//   align-items: center;
// }

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  gap: 15px;
}

.modal_code {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 80%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  color: #333;
}