.Component-Box-Search {
    width: 25%;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .toolbar_10_class {
    display: flex;
    justify-content: left;
    flex-direction: row;
  }
  
  @media (max-width: 600px) {
    .toolbar_10_class {
      display: flex;
      justify-content: left;
      flex-direction: column;
    }
    .Component-Box-Search {
      width: 100%;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    .Component-Box-Buttons {
      display: flex;
      width: 100%;
      height: auto;
      margin: 5px;
    }
  }
  