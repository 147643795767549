.logo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  gap: 25px;
}

.logo img {
  margin-top: 20px;
  max-width: 150px;
  display: flex;
}

h1 {
  color: #fff;
  font-size: 30px;
  width: 100%;
}

h1::after {
  content: '';
  display: block;
  height: 8px;
  background-color: #fff;
}


@media screen and (max-width: 768px) {
  .logo {
    flex-direction: column;
  }

  .logo img {
    max-width: 30%;
  }
  h1 {
    display: contents;
    width: 100%;
    font-size: 30px;
    align-items: center;
  }
}