.modal {
    display: flex;
    align-items: center;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 80%;
    height: 500px;
    border-radius: 10px;
    overflow-y: auto;
    padding: 20px;
}

.box-Modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}


@media screen and (max-width: 450px) {
    .modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
} 